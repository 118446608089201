/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./Documents.scss";
import FilesViewer, {
  FilesViewerAPI,
  useFilesViewer,
} from "@qualitas/files-viewer";
import FeedbackModal, { useFeedbackModal } from "@qualitas/feedback-modal";

import { IDocuments } from "./IDocuments";
import DocumentsSidebar from "./DocumentsSidebar";
import useDocuments from "../../../hooks/useDocuments";
import GenerateDocuments from "./GenerateDocuments";
import { useLocation } from "react-router";

const Documents = ({
  language,
  translations,
  getAuthToken,
  appName,
  userEmail,
  mainColor,
  setOpenedFile
}: any) => {
  const {
    peipList,
    selectedPeip,
    handleChangePeip,
    documentsBy,
    handleChangeDetailsBy,
    shouldShowFileViewer, 
    setShouldShowFileViewer,
    isSidebarLoading,
    selectedInvestors,
    investorsList,
    handleInvestorsCheckbox,
    selectedPeipDocuments,
    isTableLoading,
    setDocumentsBy
  } = useDocuments();

  const {search} = useLocation();
  
  useEffect(() => {
    if(search) setDocumentsBy(search.replace('?mode=',''))
  }, [])

  useEffect(() => {
    setShouldShowFileViewer(false);
    if (selectedPeipDocuments && language) {
      setShouldShowFileViewer(true);
    }
  }, [selectedPeipDocuments, language]);
  
  
  const baseUrl = process.env.REACT_APP_WebAPI_BaseURL || "";
  const feedbackModalProps = useFeedbackModal({});
  const api = FilesViewerAPI({
    baseUrl,
    getAuthToken,
    showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal,
  });

  const fileViewerProps = useFilesViewer({
    api,
    appName,
    idPeip: selectedPeipDocuments,
    idVehicle: undefined,
    idLanguage: language === "en" ? 1 : 2,
    addWatermark: true,
    userEmail,
});

	useEffect(() => {
		if(fileViewerProps.selectedFile){
			const selectedFile = fileViewerProps.selectedFile.split('/').pop();
			if(selectedFile) setOpenedFile(selectedFile)
		}
	}, [fileViewerProps.selectedFile]);

	return (
    <div className="layout-documents">
      <DocumentsSidebar
        selectedPeip={selectedPeip}
        handleChangePeip={handleChangePeip}
        translations={translations}
        peipList={peipList}
        documentsBy={documentsBy}
        loading={isSidebarLoading}
        handleChangeDetailsBy={handleChangeDetailsBy}
        selectedPeipDocuments={selectedPeipDocuments}
      />
        {((shouldShowFileViewer || appName === "Investor Portal") && documentsBy === 'funds') && (
        <div className="documents-container">
          <FilesViewer
              isLoading={fileViewerProps.isLoading}
              isLoadingDocument={fileViewerProps.isLoadingDocument}
              texts={{...translations, title: ''}}
              documentToken={fileViewerProps.documentToken}
              isPDFViewerOpen={fileViewerProps.isPDFViewerOpen}
              folderTree={fileViewerProps.folderTree}
              openPDFViewerModal={fileViewerProps.openPDFViewerModal}
              disableRightClick={fileViewerProps.disableRightClick}
              onSelectFile={fileViewerProps.onSelectFile}
              closePDFViewerModal={fileViewerProps.closePDFViewerModal}
              showMenu
              shouldExpandAll={fileViewerProps.shouldExpandAll}
              onExpandFolder={fileViewerProps.onExpandFolder}
              expandedKeys={fileViewerProps.expandedKeys}
              hideLoadingDocument={fileViewerProps.hideLoadingDocument}
            >
              <FeedbackModal {...feedbackModalProps} />
            </FilesViewer>
          </div>
        )}
        {documentsBy === 'investors' && 
            <GenerateDocuments 
              translations={translations} 
              investorsList={investorsList} 
              handleInvestorsCheckbox={handleInvestorsCheckbox}
              selectedInvestors={selectedInvestors}
              language={language}
              isTableLoading={isTableLoading || isSidebarLoading}
              mainColor={mainColor}
          />
        }
    </div>
  );
};

export default Documents;
