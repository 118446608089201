import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import Login, { AuthAPI, useAuth } from '@qualitas/auth';

import UnderMaintenance from './components/UnderMaintenance/UnderMaintenance';
import InvestorPortalApp from './apps/InvestorPortal/InvestorPortal';

import { initAPIs } from './Api/api';
import { ConfigProvider, RadioChangeEvent } from 'antd';
import { languages } from './Assets/idiomas';
import Chat from './Chat';

const baseUrl = process.env['REACT_APP_WebAPI_BaseURL'];
const App = () => {
	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();
	const [isLanding, setIsLanding] = useState<boolean>(false);
	const [language, setLanguage] = useState(navigator.language.split("-")[0]);
	const [translations, setTranslations]: any = useState([]);
	const [landingAppSelected, setLandingAppSelected] = useState<string>('investorportal');
	const authAPI = AuthAPI(baseUrl);
	
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: isLanding ? `https://${landingAppSelected}.qualitasfunds.com` : '/',
		systemMasterName: "Investor Portal",
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset'],
		isLanding,
		language: language
	});

	useEffect(() => {
		initAPIs(authProps.getAuthToken, authProps.userId);
	}, [authProps.userId]);

	useEffect(() => {
		switch (language) {
			case 'es': setTranslations(languages.es);
				break;
			case 'en': setTranslations(languages.en);
				break;
		}
	}, [language]);


	useEffect(() => {
		if (searchParams.has('isLanding')) {
			setIsLanding(true);
		}
		if (searchParams.has('lang')) {
			setLanguage(searchParams.get('lang') as string);
		}
	}, [searchParams]);

	const handleLandingAppSelected = (e: RadioChangeEvent) => setLandingAppSelected(e.target.value);

	return (
		<ConfigProvider
			theme={ {
				token: {
					colorPrimary: '#b3d615',
					colorInfo: '#b3d615',
					colorSuccess: '#8fac10',
					borderRadius: 0,
					borderRadiusXS: 0,
					borderRadiusSM: 0,
					borderRadiusLG: 0,
					boxShadow: '',
					boxShadowSecondary: '',
				},
				components: {
					Button: {
						primaryColor: 'rgb(0, 0, 0)',
					},
				},
			}}>
			<Routes>
				<Route path="/login" element={
					<Login {...authProps}
					appName="Investor Portal"
					isLanding={isLanding}
					landingAppSelected={landingAppSelected}
					handleLandingAppSelected={handleLandingAppSelected}
					/>
				}/>
				<Route path='/UnderMaintenance/:lang?' element={<UnderMaintenance logout={authProps.logOut}/>}/>
				<Route path='/Chatbot' element={<Chat
					language={language}
					baseUrl={baseUrl}
					getAuthToken={authProps.getAuthToken}
					showServiceFeedbackModal={authProps.showServiceFeedbackModal}
				/>}/>
				<Route path="*" element={
					<InvestorPortalApp 
					{...authProps} 
						authProps={authProps} 
						baseUrl={baseUrl} 
						translations={translations} 
						setLanguage={setLanguage} 
						language={language}
						/>
					}/>
			</Routes>
		</ConfigProvider>
	);
};

export default App;