import { useState, useEffect } from "react";

const useApp = (props: any) => {
  const { location, userPrimaryColor, userLogo, validateAuthToken} = props;

  const [welcome, setWelcome] = useState<boolean>(location.pathname === "/");
  const [firstEntrance, setFirstEntrance] = useState<boolean>(true);
  const [mainColor, setMainColor] = useState<string>(userPrimaryColor || "#B3D615");
  const [secondaryColor, setSecondaryColor] = useState<string>("#cdee3b");
  const [textColor, setTextColor] = useState<string>("#000000");
  const [openedFile, setOpenedFile] = useState<string>('')

  useEffect(() => {
    if (location.pathname === "/") {
      if(!userLogo){
        const validate = async () => {
          await validateAuthToken(`${location.pathname}`);
        };
        validate();
      }
      setTimeout(() => {
        setWelcome(false);
      }, 3000);
    }
  }, [userLogo]);

  useEffect(() => {
    setMainColor(userPrimaryColor || "#B3D615");
    setTextColor(getContrastTextColor(userPrimaryColor || "#B3D615"));
    setSecondaryColor(generateSoftColor(userPrimaryColor || "#cdee3b"));
  }, [userPrimaryColor]);

  const getContrastTextColor = (hexColor: string): string => {
    // Convertir el color hexadecimal a RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    const textColor = luminance > 0.5 ? "#000000" : "#ffffff";
    return textColor;
  };

  const generateSoftColor = (hexColor: string) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Reducir la saturación y luminosidad (puedes ajustar estos valores según tus preferencias)
    const softR = Math.floor(r * 0.85);
    const softG = Math.floor(g * 0.85);
    const softB = Math.floor(b * 0.85);

    // Convertir los nuevos valores RGB a formato hexadecimal
    const softHexColor =
      "#" +
      softR.toString(16).padStart(2, "0") +
      softG.toString(16).padStart(2, "0") +
      softB.toString(16).padStart(2, "0");
    return softHexColor;
  };

  const inLineStyle = {
    "--dynamic-color": mainColor,
    "--dynamic-text-color": textColor,
    "--dynamic-secondary-color": secondaryColor,
  } as React.CSSProperties;

  return {
    welcome,
    mainColor,
    inLineStyle,
	openedFile,
	setOpenedFile,
    firstEntrance, 
    setFirstEntrance
  };
};

export default useApp;
