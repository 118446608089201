import { AiChatbotAPI, useChatbot } from "@qualitas/aichatbot";

import DocumentViewer, {
  useDocumentViewer,
  DocumentViewerAPI,
} from "@qualitas/documentviewer";
import useDocumentGeneration from './hooks/useDocumentGeneration';
import { useEffect } from "react";

const Chat = (props: /* IHomeProps */ any) => {
    const { 
		language,
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal,
	 } = props

    const {
        viewReport,
      } = useDocumentGeneration({
          selectedInvestors: {idVehicle: 0, investors: []}, 
          language
    });

	const apiChatbot = AiChatbotAPI({ baseUrl, getAuthToken });
	const needsBot = true;
	const chatbotProps = useChatbot({
	  needsBot,
	  api: apiChatbot,
	  language,
	  hasAvatar: false,
	  documentName: '2023Q4_QF IV_Report_Qualitas_2_8912.pdf',
	  isModal: true,
	  setDocumentName: () => {},
	  documentId: 8912,
	});
  
	const api = DocumentViewerAPI({
	  baseUrl,
	  getAuthToken,
	  showServiceFeedbackModal,
	});
	const documentViewerProps = useDocumentViewer({
	  api,
	  setShowBot: chatbotProps.setShowBot,
	  setShowBotButton: chatbotProps.setShowBotButton,
	});

	useEffect(()=> {
		documentViewerProps.openFileUrl(8912);
	}, [])
    
  return (
	<DocumentViewer {...documentViewerProps} chatbotProps={chatbotProps} />

  )
}

export default Chat