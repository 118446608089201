import { BellOutlined, CaretRightOutlined, EyeOutlined, InfoCircleOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Empty, Input, Skeleton, Spin, Tooltip } from 'antd';
import { formatNumber } from '../../../utils/Numbers';
import { IHomeProps } from './IHome';
import HomeFlows from './HomeFlows';
import RecentActivity from './RecentActivity';
import useDocumentGeneration from '../../../hooks/useDocumentGeneration';
import dayjs from 'dayjs';
import DocumentViewer, {
	useDocumentViewer,
	DocumentViewerAPI,
  } from "@qualitas/documentviewer";
  
import './Home.scss';
import useInvestors from '../../../hooks/useInvestors';
import { AiChatbotAPI, useChatbot } from "@qualitas/aichatbot";
import Pagination from './Pagination';

const Home = (props: /* IHomeProps */ any) => {
    const { 
		translations, 
		language, mainColor,     
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal,
		firstEntrance, 
		setFirstEntrance
	 } = props

    const { 
        investorsData, 
        loading, 
        viewCapitalCall, 
        quarter, 
        homeFlowsChart, 
        selectedInvestorsFlowChart, 
        handleInvestorsCheckboxFlowChart,
        quarterDate,
		documentId,
		documentName, 
		setDocumentName,
		setDocumentId,
        filterInvestors,
		pagination,
		getFundFigures,
		moreInvestorsToSearch,
		searchValue	
    } = useInvestors({language, firstEntrance, setFirstEntrance});

    const {
        viewReport,
      } = useDocumentGeneration({
          selectedInvestors: {idVehicle: 0, investors: []}, 
          language
    });

	const apiChatbot = AiChatbotAPI({ baseUrl, getAuthToken });
	const needsBot = true;
	const chatbotProps = useChatbot({
	  needsBot,
	  api: apiChatbot,
	  language,
	  hasAvatar: false,
	  documentName,
	  isModal: true,
	  setDocumentName,
	  documentId,
	});
  
	const api = DocumentViewerAPI({
	  baseUrl,
	  getAuthToken,
	  showServiceFeedbackModal,
	});
	const documentViewerProps = useDocumentViewer({
	  api,
	  setShowBot: chatbotProps.setShowBot,
	  setShowBotButton: chatbotProps.setShowBotButton,
	});

	const selectFile = (id: number) => {
		documentViewerProps.openFileUrl(id);
		setDocumentId(id);
	}
    
  return (
	<>
      <Spin spinning={documentViewerProps.loading} fullscreen />
		<DocumentViewer {...documentViewerProps} chatbotProps={chatbotProps} />
		<div className="home">
			<h1>{translations.bienvenido} </h1>
			<h2>{translations.resumen} 
				<span>{quarter && ` | ${translations.informacionAFecha} ${quarter}`}</span>
			</h2>
			<Spin
				size="large"
				spinning={loading.table}
				indicator={<LoadingOutlined style={{ fontSize: 46, color: mainColor }} />}
			>
				<table className="home-table">
					<colgroup>
						<col style={{width: "18%"}} />
						<col style={{width: "8%"}} />
						<col style={{width: "8%"}} />
						<col style={{width: "8%"}} />
						<col style={{width: "8%"}} />
						<col style={{width: "8%"}} />
						<col style={{width: "7%"}} />
						{/* <col style={{width: "6%"}} />
						<col style={{width: "6%"}} />
						<col style={{width: "6%"}} />
						<col style={{width: "6%"}} />
						<col style={{width: "6%"}} />*/}
						<col style={{width: "7%"}} />
						<col style={{width: "7%"}} />
						<col style={{width: "7%"}} />
						<col style={{width: "7%"}} />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>{translations.compromiso}</th>
							<th>{translations.desembolsos}</th>
							<th>{translations.distribuciones}</th>
							<th>{translations.pendiente}</th>
							<th>{translations.valoracion}</th>
							<th>{translations.certificadoParticipacion}</th>
							<th className="grey-background">DPI</th>
							<th className="grey-background">RVPI</th>
							<th className="grey-background">TVPI</th>
							{/* <th className="grey-background">TIR</th> */}
							<th className="grey-background">{translations.grafico}</th>
						</tr>
					</thead>
					<tbody>
						{investorsData.map((item: any, index: number) => {
							return (
								<>
								<tr key={index}>
									<td className="program-name"><span><CaretRightOutlined /> </span>{item.peipName}</td>
									<td className="currency-tag"><span>EUR</span></td>
									<td colSpan={5}><span></span></td>
									<td colSpan={5} className="grey-background report-btn">
										<button onClick={()=> selectFile(item.reportURL)} >
											{translations.verInforme} Q4 2023 <span><CaretRightOutlined /></span>
										</button>
									</td>
								</tr> 
								{item.investorsList.map((investor: any, index: number) => {
									return(
									<tr key={index}>
										<td className="investment-name">{investor.investorName} 
											{!investor.paid && 
											<span className="danger">
												<Tooltip placement="right" title={translations.capitalCallNoPago}>
													<button
														className="bell-btn" 
														onClick={()=> viewCapitalCall(investor.investorId, investor.capitalCallDate)}>
														<BellOutlined/>
													</button>
												</Tooltip>
											</span>
											}
										</td>
										<td>{formatNumber(investor.commitment)}</td>
										<td>{formatNumber(investor.totalCapitalCalls)}</td>
										<td>{formatNumber(investor.totalDistributions)}</td>
										<td>{formatNumber(investor.pending)}</td>
										<td>{formatNumber(investor.valuation)} </td>
										<td>
											<Tooltip 
												placement="right" 
												title={`${translations.certificadoDeParticipacionAFecha}: ${dayjs(quarterDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}
												>
												<button onClick={()=> viewReport('CapitalAccount', dayjs(quarterDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), [investor.investorId])}
													className="view-documents-btn"><EyeOutlined style={{color: 'black'}}/>
												</button>
											</Tooltip>
										</td>
										<td className="grey-background">{investor.dpi}</td>
										<td className="grey-background">{investor.rvpi}</td>
										<td className="grey-background">{investor.tvpi}</td>
										{/* <td className="grey-background">{investor.tir}</td> */}
										<td className="grey-background">
											<input type='checkbox'
												checked={selectedInvestorsFlowChart.includes(investor.investorId)}
												onChange={(e) => handleInvestorsCheckboxFlowChart(e.target.checked,investor.investorId)}
											/>
										</td>
									</tr>
								)})}
								</>
							)
						}
					)}
				{moreInvestorsToSearch > 0 &&
					<tr>
						<td className="search-exceded" colSpan={11}>
							<InfoCircleOutlined /> {translations.moreThan} {moreInvestorsToSearch} {translations.noResults}
						</td>
					</tr>
				}
					</tbody>
				</table>
				{(!searchValue && pagination.viewed > 0) && 
					<Pagination 
						mainColor={mainColor} 
						viewed={pagination.viewed} 
						total={pagination.total}
						onLoadMore={() => getFundFigures(pagination)}	
						translations={translations}
					/>
				}
				{(loading.table && investorsData.length === 0) &&
					<>
						<Skeleton className='skeleton-loading' active />
					</>
				}
				{!loading.table && investorsData.length === 0 && 
					<div className="empty-data">
						<Empty description={translations.noDataFound} />
					</div>
				}
				<div className="home-footer">
					<RecentActivity 
						translations={translations}
						language={language} 
						mainColor={mainColor}
						viewReport={viewReport}
					/>
					{(selectedInvestorsFlowChart.length > 0) && 
						<HomeFlows 
							homeFlowsChart={homeFlowsChart} 
							translations={translations} 
							loading={loading.chart} 
							mainColor={mainColor}/>
					}
					{(selectedInvestorsFlowChart.length === 0 && !loading.chart && !loading.table) && 
						<div className="empty-data empty-data-chart">
							<p>
								{translations.noInvestorsSelected}
							</p>
						</div>
					}
				</div>
			</Spin>
		</div>
	</>
  )
}

export default Home